const Footer = () => {
  return (
    <div className="flex flex-col md:flex-row w-full justify-between px-10 md:px-20 py-5 text-white">
      <div className="flex flex-col mb-4 md:mb-0">
        <h2>CALL</h2>
        <h3>
          <a href="tel:0277017822" className="text-white hover:underline">Hayden - 0277017822</a>
        </h3>
        <h3>
          <a href="tel:073773397" className="text-white hover:underline">Shop - 073773397</a>
        </h3>
      </div>
      <div className="flex flex-col">
        <h2>EMAIL</h2>
        <h3>
          <a href="mailto:taupofarmandgame@gmail.com" className="text-white hover:underline">taupofarmandgame@gmail.com</a>
        </h3>
      </div>
    </div>
  );
};

export default Footer;
