import React, { PropsWithChildren } from "react";
import Footer from "./Footer";

const MainLayout: React.FC<PropsWithChildren<{ navbar?: JSX.Element }>> = ({
  children,
  navbar,
}) => {
  return (
    <div className="w-full h-full flex flex-grow justify-stretch bg-grey-600 flex-col items-center px-10 protest-strike-regular">
      {navbar}
      <main className="w-full h-full relative text-black text-center items-center p-2">{children}</main>
      <Footer />
    </div>
  );
};

export default MainLayout;
