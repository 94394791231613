import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import MainLayout from "./MainLayout";
import NavBar from "./NavBar";
import logo from "../src/assets/logo.png";

const Home = lazy(() => import("./Home"));
const About = lazy(() => import("./About"));
const Services = lazy(() => import("./Services"));
const Gallery = lazy(() => import("./Gallery"));

const Loading = () => (
  <div className="flex justify-center items-center h-screen">
    <img alt="logo" src={logo} />
  </div>
);

function App() {
  return (
    <Router>
      <MainLayout navbar={<NavBar />}>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
        </Suspense>
      </MainLayout>
    </Router>
  );
}

export default App;
