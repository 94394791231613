import { NavLink } from "react-router-dom";
import logo from "../src/assets/logo.png";

const NavBar = () => {
  return (
    <nav className="w-full flex items-center justify-between my-3 text-white bg-grey-600 p-2">
      <NavLink to="/">
        <img src={logo} alt="logo" className="h-10 w-auto" />
      </NavLink>
      <div className="flex items-center ml-auto pr-20 protest-strike-regular font-bold">
        <NavLink to="/" className="px-4 romanStandard">
          Home
        </NavLink>
        <NavLink to="/about" className="px-4 romanStandard">
          Our Team
        </NavLink>
        <NavLink to="/services" className="px-4 romanStandard">
          Cutting List
        </NavLink>
        <NavLink to="/gallery" className="px-4 romanStandard">
          Gallery
        </NavLink>
      </div>
    </nav>
  );
};

export default NavBar;
